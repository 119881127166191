/* eslint-disable prefer-const */
import axios from 'axios'
import { Toast } from 'vant'

export function req (arg) {
  var option = {
    'method': 'post', // ajax类型
    // 'pathname': 'http://127.0.0.1:5103/console/api/', // 请求的pathname，proxy使用
    'pathname': 'https://etopsapi.thvai.com/console/api/', // 请求的pathname，proxy使用
    'url': '', // url
    'data': {}, // 传参，兼容处理，无论get post 都配置这里
    'headers': {
      'Content-Type': 'application/json',
    },
    'timeout': 600 * 1000, // 超时时间
    'responseType': 'json', // 参数类型

    'loadingText': '', // loading文案
    'message': '', // 成功后提示信息
    'finish': null, // 请求后回调
    'before': null, // 请求前回调

    'fail': null, // 失败回调
    'success': null, // http200成功后回调函数,最高优先级
    'callback': null, // errno=0成功后回调函数
    'errorCallback': null, // 失败后回调函数
    'errorShow': null // 错误提示自定义函数
  }

  option = Object.assign(option, arg)

  // option.data.isIOS = isIOS()

  // get
  if (option.method === 'get' && option.data) {
    option.params = option.data
  }

  // if (option.data && Object.keys(option.data) && Object.keys(option.data).length) {
  //   let formData = new FormData()
  //   for (let key in option.data) {
  //     formData.append(key, option.data[key])
  //   }
  //   option.data = formData
  // }

  // 执行前钩子
  option.before && option.before(option)

  // loading动画
  // @todo 超过1.5秒自动弹出
  let loadingToast = option.loadingText
    ? Toast.loading({
      message: option.loadingText
    })
    : null
  
  console.log(option)
  // 发起请求
  return axios({
    method: option.method,
    url: option.pathname + option.url,
    data: option.data,
    params: option.params,
    headers: option.headers,
    timeout: option.timeout,
    responseType: option.responseType
  })
    .then(function (response) {
      let rs = response.data || {}

      loadingToast && loadingToast.clear()
      option.finish && option.finish(rs)

      if (option.success) {
        option.success(rs)
        return
      }

      // 成功
      if (rs.errno === 0) {
        option.callback && option.callback(rs.data)
        if (option.message) {
          Toast.success({
            message: option.message,
            duration: 1200
          })
        }
      } else {
        // 未登录，单独处理
        if (rs.errno === 20) {
          Toast.fail(rs.errmsg || '请先登录')
          setTimeout(function () {
            
          }, 1800)
          return
        }

        // 其他错误
        option.errorCallback && option.errorCallback(rs)

        if (option.errorShow) {
          // 自定义错误提示
          option.errorShow(rs)
        } else {
          // 通用错误提示
          Toast.fail(rs.errmsg || '数据请求错误')
        }
      }
    })
    .catch(function (error) {
      console.log(error)
      loadingToast && loadingToast.clear()
      option.finish && option.finish(error)
      option.fail && option.fail(error)
      Toast.fail('网络异常，请稍后重试')
    })
}
